import { Button, Modal } from "antd";
import { ReactNode } from "react";

const ModalWrapper = ({
  children,
  text,
  toggleModalFn,
  showModal = false,
  okFn,
  buttonProps,
  ...props
}: {
  children: ReactNode;
  text: string;
  toggleModalFn: Function;
  showModal: boolean;
  okFn: Function;
  [key: string]: any;
  buttonProps?: any;
}) => {
  return (
    <>
      <Button onClick={() => toggleModalFn(true)} {...buttonProps}>
        {text}
      </Button>
      <Modal
        confirmLoading={props.loading || false}
        open={showModal}
        onCancel={() => toggleModalFn(false)}
        onOk={async () => {
          await okFn();
          toggleModalFn(false);
        }}
        {...props}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalWrapper;
