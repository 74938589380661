import ModalWrapper from "./ModalWrapper";
import { message, Select, Form, Button, Input, Space, InputNumber } from "antd";
import { useState, useEffect, useCallback } from "react";
import { useSession } from "next-auth/react";
import axios from "axios";

const { Option } = Select;

const OfflineSubscription = ({
  email,
  paymentEmail,
  callback,
}: {
  email: string;
  paymentEmail?: string;
  callback: Function;
}) => {
  const { data: session } = useSession();
  const [showModal, setShowModal] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [submitable, setSubmitable] = useState(true); // submit state
  const [selProduct, setSelProduct] = useState([]);
  const [selState, setSelState] = useState(false); //
  const [paymentEmailFlag, setPaymentEmailFlag] = useState(true); //
  const [couponMaxNum, setCouponMaxNum] = useState(100); //

  const [form] = Form.useForm();
  // Watch all values
  const values = Form.useWatch([], form);

  const toggleModalFn = useCallback((visible: boolean) => {
    if (visible) {
      getSelProduct();
      if (paymentEmail) {
        form.setFieldValue("payment_email", paymentEmail);
        setPaymentEmailFlag(false);
      } else {
        form.setFieldValue("payment_email", undefined);
        setPaymentEmailFlag(true);
      }
    }
    setShowModal(visible);
  }, []);

  useEffect(() => {
    if (!showModal) return;

    const formData = form.getFieldsValue(true);

    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmitable(true);
      },
      () => {
        setSubmitable(false);
      }
    );
  }, [showModal, values]);

  const getSelProduct = useCallback(async () => {
    setSelState(true);

    try {
      const res = await axios.get("/api/list_prices");

      setSelState(false);

      if (res.status === 200 && res.data.data && Array.isArray(res.data.data)) {
        const selProData = res.data.data
          .filter((gr: Record<string, any>) => gr.active && gr.recurring)
          .map((item: Record<string, any>, index: number) => {
            return {
              index,
              value: item.id,
              unit_amount: item.unit_amount / 100,
              label: item.product.name,
              currency: item.currency,
            };
          });

        setSelProduct(selProData);
      }
    } catch (error) {
      setSelState(false);
    }
  }, [setSelProduct]);

  // uopload file
  const handleUpload = async () => {
    const formData = form.getFieldsValue(true);

    let coupon_amount_off = undefined;
    let coupon_percent_off = undefined;
    if (formData.coupon) {
      if (formData.coupon.type === "100") {
        coupon_percent_off = formData.coupon.value;
      } else {
        coupon_amount_off = formData.coupon.value;
      }
    }
    setUploading(true);

    try {
      const res = await axios.post("/api/offline_subscription", {
        email,
        payment_email: formData.payment_email,
        stripe_customer_name: formData.stripe_customer_name,
        stripe_price_id: formData.stripe_price_id,
        // stripe_price_id: "price_1O1mOTIlpOoXNN9KW0uKC8yM",
        days_until_due: formData.days_until_due,
        originalPrice: formData.originalPrice,
        coupon_percent_off,
        coupon_amount_off,
        coupon_name: formData.coupon_name,
        operator_username: session?.user.username,
      });

      setUploading(false);

      if (res.status === 200 && res.data.message === "success") {
        callback();
        message.success("submit successfully.");
      } else {
        message.error("submit failed.");
      }
    } catch (error) {
      setUploading(false);
      message.error("submit failed.");
    }
  };

  // canncel
  const handleCancel = () => {
    form.resetFields();
    setSelProduct([]);
    setShowModal(false);
  };

  const selProChange = (value: any, option: any) => {
    console.log("selProChange", value, option);
    form.setFieldValue("originalPrice", option.unit_amount);
  };

  const couponTypeChange = (value: any, option: any) => {
    setCouponMaxNum(Number(value));
    form.setFieldValue(["coupon", "value"], undefined);
  };

  return (
    <ModalWrapper
      text="Offline Subscription"
      showModal={showModal}
      toggleModalFn={toggleModalFn}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="offlinePayForm"
        colon={false}
        form={form}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        style={{ maxWidth: 800 }}
      >
        <h3>Offline Subscription</h3>
        <p style={{ color: "red" }}>*You are creating a recurring plan for this user.</p>
        <Form.Item label="Select Product" name="stripe_price_id" rules={[{ required: true }]}>
          <Select
            loading={selState}
            style={{ width: 250 }}
            options={selProduct}
            onChange={selProChange}
          />
        </Form.Item>
        <Form.Item label="Original Price" name="originalPrice" rules={[{ required: true }]}>
          <Input prefix="$" style={{ width: 250 }} readOnly={true} />
        </Form.Item>
        <Form.Item label="Payment Email" name="payment_email" rules={[{ required: true }]}>
          <Input style={{ width: 250 }} readOnly={!paymentEmailFlag} />
        </Form.Item>
        <Form.Item label="Coupon">
          <Space.Compact>
            <Form.Item name={["coupon", "value"]} noStyle>
              <InputNumber max={couponMaxNum} min={0} style={{ width: 80 }} />
            </Form.Item>
            <Form.Item name={["coupon", "type"]} noStyle>
              <Select style={{ width: 170 }} onChange={couponTypeChange}>
                <Option value="100">Percentage off</Option>
                <Option value="1000000000">Fixed Discount</Option>
              </Select>
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Item label="Coupon Name" name="coupon_name">
          <Input style={{ width: 250 }} />
        </Form.Item>

        <Form.Item label="Customer Name" name="stripe_customer_name">
          <Input style={{ width: 250 }} />
        </Form.Item>

        <Form.Item label="Payment Deadline" name="days_until_due" rules={[{ required: true }]}>
          <Space>
            <InputNumber min={0} style={{ width: 160 }} />
            <div>days</div>
          </Space>
        </Form.Item>
        <div style={{ textAlign: "right", paddingTop: 10 }}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={handleUpload}
            loading={uploading}
            disabled={!submitable}
            type="primary"
            style={{ marginLeft: 10 }}
          >
            Submit
          </Button>
        </div>
      </Form>
    </ModalWrapper>
  );
};

export default OfflineSubscription;
