export enum MembershipLevel {
  FREE = "FREE",
  STANDARD = "STANDARD",
  FREE_TRIAL = "FreeTrial",
  PRO = "PRO",
  BUSINESS = "BUSINESS",
  BUSINESS_TRIAL = "BusinessTrial",
}

export enum OPERATION_PLATFORM_USER_STATUS {
  REGISTERED = "Registered",
  EXPIRED = "Expired",
  TRIAL = "Trial",
  CANCELLED = "Cancelled",
  ACTIVE = "Active",
}

export enum MembershipStatus {
  TRIAL_ACTIVE = "TRIAL_ACTIVE",
  TRIAL_INACTIVE = "TRIAL_INACTIVE",
  TRIAL_EXPIRE = "TRIAL_EXPIRE",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  EXPIRE = "EXPIRE",
}

export enum PaymentType {
  OFFLINE_STRIPE = "OFFLINE_STRIPE",
  ONLINE_STRIPE = "ONLINE_STRIPE",
  OFFLINE_PAYMENT = "OFFLINE_PAYMENT",
  OFFLINE_GROUP_SUBSCRIPTION = "OFFLINE_GROUP_SUBSCRIPTION",
}

export enum AccountType {
  NORMAL = "Normal",
  MAIN = "Main",
  SEAT = "Seat",
}

export enum PlanInterval {
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}
