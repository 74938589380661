import ModalWrapper from "./ModalWrapper";
import { DatePicker, message, Select, Form, Button, Upload, Input, Radio } from "antd";
import { useState, useEffect } from "react";
import { useSession } from "next-auth/react";
import dayjs from "dayjs";
import { offlineTimerOptions, offlinePlanOptions } from "../common/const";
import { UploadOutlined } from "@ant-design/icons";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { STRIPE_URL_PREFIX } from "@/common/constants";

const { TextArea } = Input;
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const OfflinePayment = ({
  email,
  callback,
  stripeSubscriptionId,
}: {
  email: string;
  callback: Function;
  stripeSubscriptionId: any;
}) => {
  const tranTimestamp = (day: string) => {
    return dayjs().add(Number(day), "day").valueOf();
  };

  const { data: session } = useSession();
  const [timeType, setTimeType] = useState("");
  const [showModal, setShowModal] = useState(false);

  // files
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [submitable, setSubmitable] = useState(false); // submit state

  const [form] = Form.useForm();
  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(() => {
    const formData = form.getFieldsValue(true);

    setTimeType(formData.membership_end);

    if (
      formData.membership_end === "Custom" &&
      formData.membership_level &&
      formData.date &&
      fileList.length > 0
    ) {
      setSubmitable(true);
    } else if (
      formData.membership_level &&
      formData.membership_end &&
      formData.membership_end !== "Custom" &&
      fileList.length > 0
    ) {
      setSubmitable(true);
    } else {
      setSubmitable(false);
    }
  }, [values, fileList]);

  // upload props
  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (fileList.length < 3) {
        setFileList([...fileList, file]);
      } else {
        message.error("Maximum of three files can be uploaded.");
      }

      return false;
    },
    fileList,
  };

  // uopload file
  const handleUpload = () => {
    const formData = new FormData() as any;
    fileList.forEach((file) => {
      formData.append("files", file as FileType);
    });

    const { membership_level, membership_end, date, remark, isS3LoggingEnabled } =
      form.getFieldsValue(true);
    formData.append("email", email);
    formData.append("membership_level", membership_level);
    formData.append(
      "membership_end",
      membership_end === "Custom" ? dayjs(date).valueOf() : tranTimestamp(membership_end)
    );
    formData.append("operator_username", session?.user.username as string);
    formData.append("isS3LoggingEnabled", !Boolean(Number(isS3LoggingEnabled)));
    formData.append("remark", remark);

    setUploading(true);
    fetch("/api/offline_payment", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        setFileList([]);
        callback();
        message.success("submit successfully.");
      })
      .catch(() => {
        message.error("submit failed.");
      })
      .finally(() => {
        setUploading(false);
      });
  };

  // canncel
  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    setShowModal(false);
  };

  return (
    <ModalWrapper
      text="Offline Payment"
      showModal={showModal}
      toggleModalFn={setShowModal}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="offlinePayForm"
        colon={false}
        form={form}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        style={{ maxWidth: 800 }}
      >
        <h3>Offline Payment</h3>

        <Form.Item label="Upload Payment Receipt">
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item label="Set Plan" name="membership_level">
          <Select style={{ width: 200 }} options={offlinePlanOptions} />
        </Form.Item>
        <Form.Item label="Set expiration date" name="membership_end">
          <Select style={{ width: 200 }} options={offlineTimerOptions} />
        </Form.Item>
        {timeType == "Custom" && (
          <Form.Item label=" " name="date">
            <DatePicker style={{ width: 200 }} showTime />
          </Form.Item>
        )}

        <Form.Item label="Add to ARR?" name="isS3LoggingEnabled">
          <Radio.Group defaultValue={"0"}>
            <Radio value={"0"}>Yes</Radio>
            <Radio value={"1"}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Remark" name="remark">
          <TextArea autoSize={{ minRows: 4 }} />
        </Form.Item>

        {!!stripeSubscriptionId && (
          <p style={{ color: "red" }}>
            * The user has an active subscription. Please{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={`${
                STRIPE_URL_PREFIX[process.env.NEXT_PUBLIC_STAGE!]
              }/subscriptions/${stripeSubscriptionId}`}
              onClick={() => {
                handleCancel();
              }}
            >
              cancel it
            </a>{" "}
            before offline payment.
          </p>
        )}

        <div style={{ textAlign: "right", paddingTop: 10 }}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={handleUpload}
            disabled={!submitable || !!stripeSubscriptionId}
            loading={uploading}
            type="primary"
            style={{ marginLeft: 10 }}
          >
            Submit
          </Button>
        </div>
      </Form>
    </ModalWrapper>
  );
};

export default OfflinePayment;
