import {MembershipLevel} from "../backend/enums/user_enums"

export function convertPlanContent(plan: string): string {
    if (plan == MembershipLevel.FREE_TRIAL) {
      return "Pro Trial";
    }
    if(plan == "BUSINESS" || plan == MembershipLevel.BUSINESS){
      return "Elite";
    }
    if(plan == "BUSINESS_TRIAL" ||  plan == MembershipLevel.BUSINESS_TRIAL){
      return "EliteTrial";
    }
    return plan;
  
  }
  