import ModalWrapper from "./ModalWrapper";
import { DatePicker, message, Select } from "antd";
import { useState } from "react";
import axios from "axios";
import { useSession } from "next-auth/react";
import dayjs from "dayjs";
import { timeSelectOptions } from "../common/const";

const ExtendPlan = ({
  email,
  expirationTime,
  callback,
  planToExtend,
}: {
  email: string;
  expirationTime?: number;
  callback: Function;
  planToExtend: string;
}) => {
  const { data: session } = useSession();
  const [date, setDate] = useState<any>();
  const [pickerDate, setPickerDate] = useState<any>();
  const [timeType, setTimeType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const addTime = (days: number) => {
    return days * 24 * 60 * 60 * 1000;
  };
  const handleSelectChange = (val: string) => {
    setTimeType(val);
    if (expirationTime) {
      let now = dayjs(expirationTime).valueOf();
      let tempTime = 0;
      if (val !== "Custom") {
        tempTime = Math.abs(now) + addTime(Number(val));
      } else {
        setPickerDate("");
        tempTime = 0;
      }
      setDate(tempTime);
    }
  };

  const handlePickChange = (val: any) => {
    setPickerDate(val);
    setDate(dayjs(val).valueOf());
  };

  return (
    <ModalWrapper
      text="Extend Plan"
      showModal={showModal}
      toggleModalFn={setShowModal}
      loading={loading}
      okFn={async () => {
        if (!date) {
          message.warning("Please select a time");
          return;
        }
        setLoading(true);
        try {
          await axios.post("/api/extend_plan", {
            email,
            expireDate: date,
            operator_id: session?.user.sub,
            operator_username: session?.user.username,
          });
        } catch (e: any) {
          message.error(`extend plan failed: ${e.response.data.message}`);
          throw e;
        } finally {
          setLoading(false);
        }
        callback();
      }}
    >
      <>
        <p>{email}</p>
        <p>Extend {planToExtend} Plan</p>
        <Select
          value={timeType}
          style={{ width: 120 }}
          onChange={handleSelectChange}
          options={timeSelectOptions}
        />
        {timeType == "Custom" && (
          <DatePicker
            value={pickerDate}
            showTime
            onChange={handlePickChange}
            style={{ marginLeft: "10px" }}
          />
        )}
      </>
    </ModalWrapper>
  );
};

export default ExtendPlan;
