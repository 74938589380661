import ModalWrapper from "./ModalWrapper";
import { useState, useCallback } from "react";
import { useSession } from "next-auth/react";
import { message } from "antd";
import { STRIPE_URL_PREFIX } from "@/common/constants";
import axios from "axios";

const SetToFree = ({
  email,
  stripeSubscriptionId,
  callback,
}: {
  email: string;
  stripeSubscriptionId?: string;
  callback: Function;
}) => {
  const [showModal, setShowModal] = useState(false);
  const { data: session } = useSession();

  const toggleModalFn = useCallback((visible: boolean) => {
    setShowModal(visible);
  }, []);

  // canncel
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOk = async () => {
    try {
      await axios.post("/api/set_to_free", {
        email,
        operator_username: session?.user.username,
      });
      callback();
    } catch (e: any) {
      const req = e.response;
      if (req.status == 400) {
        message.error(req.data.msg || req.data.errMsg);
        return;
      }
      message.error("request fail");
    }
  };
  return (
    <>
      <ModalWrapper
        text="Set to free"
        showModal={showModal}
        toggleModalFn={toggleModalFn}
        onCancel={handleCancel}
        // footer={null}
        okFn={handleOk}
        title="Set to free"
        okButtonProps={{
          disabled: !!stripeSubscriptionId,
        }}
      >
        The user will be FREE immediately. Do you wanna to proceed?
        {!!stripeSubscriptionId && (
          <p style={{ color: "red" }}>
            *The user has an active subscription. Please click{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={`${
                STRIPE_URL_PREFIX[process.env.NEXT_PUBLIC_STAGE!]
              }/subscriptions/${stripeSubscriptionId}`}
              onClick={() => {
                handleCancel();
              }}
            >
              here{" "}
            </a>
            to cancel it in stripe and he will be FREE.
          </p>
        )}
      </ModalWrapper>
    </>
  );
};

export default SetToFree;
