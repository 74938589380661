import { Button, Table } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import Modalwrapper from "./ModalWrapper";
import { STRIPE_URL_PREFIX } from "@/common/constants";

const OperatorLog = ({ email }: { email: string }) => {
  const [nextToken, setNextToken] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [logData, setLogdata] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  const requestLog = useCallback(() => {
    setLoading(true);
    axios
      .get("/api/list_user_operation_logs", {
        params: { email, nextToken },
      })
      .then((res) => {
        const { data } = res;
        setLogdata([...logData, ...data.operationLogResponseList]);
        setNextToken(data.nextToken);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [nextToken, email]);

  const toggleModalFn = useCallback((visible: boolean) => {
    if (visible) {
      requestLog();
    } else {
      setLogdata([]);
    }
    setShowModal(visible);
  }, []);

  const columns = [
    {
      dataIndex: "created_time",
      title: "Time",
      width: 200,
      render: (text: any) => dayjs(Number.parseInt(text)).format("YYYY-MM-DD HH:mm"),
    },
    {
      dataIndex: "action",
      title: "Action",
      width: 250,
    },
    { dataIndex: "operator_username", title: "Operator" },
    {
      dataIndex: "offline_payment_attachments_s3_keys",
      title: "OfflinePaymentFile",
      render: (text, record) => {
        if (record.offline_payment_attachments_s3_keys) {
          return (
            <>
              {JSON.parse(record.offline_payment_attachments_s3_keys).map(
                (item: any, index: any) => (
                  <p key={index}>
                    <a href={record.offline_payment_attachments[index]}>{item}</a>
                  </p>
                )
              )}
            </>
          );
        }
        return "-";
      },
    },
    {
      dataIndex: "invoice",
      title: "Invoice",
      width: 150,
      render: (text, record) => {
        return record.invoice ? (
          <>
            <a
              href={`${STRIPE_URL_PREFIX[process.env.NEXT_PUBLIC_STAGE!]}/invoices/${
                record.invoice
              }`}
            >
              Invocie Page
            </a>
          </>
        ) : (
          <></>
        );
      },
    },
    {
      dataIndex: "remark",
      title: "Remark",
      width: 300,
    },
  ];

  return (
    <Modalwrapper
      text="Log"
      showModal={showModal}
      toggleModalFn={toggleModalFn}
      okFn={() => setShowModal(false)}
      width={1200}
    >
      <p>Operation log</p>
      <Button
        disabled={!nextToken}
        loading={loading}
        onClick={() => {
          requestLog();
        }}
      >
        Next
      </Button>
      <Table
        rowKey={"created_time"}
        loading={loading}
        dataSource={logData.sort((a, b) => b - a)}
        columns={columns}
      />
    </Modalwrapper>
  );
};

export default OperatorLog;
