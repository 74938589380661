export const timeSelectOptions = [
  {
    label: "4 days",
    value: "4",
  },
  {
    label: "1 week",
    value: "7",
  },
  {
    label: "2 weeks",
    value: "14",
  },
  {
    label: "31 days",
    value: "31",
  },
  {
    label: "Custom date",
    value: "Custom",
  },
];

export const offlineTimerOptions = [
  {
    label: "One Month",
    value: "31",
  },
  {
    label: "One Year",
    value: "365",
  },
  {
    label: "Custom Date",
    value: "Custom",
  },
];

export const offlinePlanOptions = [
  {
    label: "STANDARD",
    value: "STANDARD",
  },
  {
    label: "PRO",
    value: "PRO",
  },
  {
    label: "BUSINESS",
    value: "BUSINESS",
  },
];

export const homePaymentType = [
  {
    label: "OFFLINE_STRIPE",
    value: "OFFLINE_STRIPE",
  },
  {
    label: "OFFLINE_PAYMENT",
    value: "OFFLINE_PAYMENT",
  },
  {
    label: "ONLINE_STRIPE",
    value: "ONLINE_STRIPE",
  },
];

export const demoOptions = [
  {
    label: "demoed",
    value: "demoed",
  },
  {
    label: "scheduled",
    value: "scheduled",
  },
  {
    label: "Not yet booked",
    value: "Not yet booked",
  },
];

export const blockTypeOptions = [
  {
    label: "fingerprint",
    value: "fingerprint",
  },
];

export const riskTypeOptions = [
  {
    label: "low",
    value: "low",
  },
  {
    label: "medium",
    value: "medium",
  },
  {
    label: "high",
    value: "high",
  },
];
