import { ComponentAuthProtect } from "@/common/ComponentAuthProtect";
import { STRIPE_URL_PREFIX } from "@/common/constants";
import { convertPlanContent } from "@/src/common/tools";
import { EditOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Table, TableColumnProps, message, Dropdown } from "antd";
import axios from "axios";
import classnames from "classnames";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import style from "../../styles/homePage.module.scss";
import { MembershipLevel, OPERATION_PLATFORM_USER_STATUS } from "../backend/enums/user_enums";
import type { UserResponse } from "../backend/types/response";
import Applytrial from "../components/ApplyTrial";
import ExtendPlan from "../components/ExtendPlan";
import OfflinePayment from "../components/OfflinePayment";
import OfflineSubscription from "../components/OfflineSubscription";
import SetToFree from "../components/SetToFree";
import OperatorLog from "../components/OperatorLog";
import EditColData from "../components/editColData";
import GroupSubDialog from "../components/GroupSubDialog";
import { homePaymentType } from "../common/const";
import type { TableProps } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";

const { Option } = Select;

const PAGE_SIZE = 50;
const INIT_DATA_REQUEST = {
  current: 1,
  limit: PAGE_SIZE,
  nextToken: undefined,
};

type INIT_FILTERS_REF = {
  email: string;
  currentPlans: string;
  minCountDownDate: string;
  maxCountDownDate: string;
  minUserLifeTime: string;
  maxUserLifeTime: string;
  status: string;
};

const INIT_FILTERS = {
  email: "",
  currentPlans: "",
  minCountDownDate: "",
  maxCountDownDate: "",
  minUserLifeTime: "",
  maxUserLifeTime: "",
  status: "",
};

const Home = () => {
  const [form] = Form.useForm();
  let dataRequest = useRef(INIT_DATA_REQUEST);
  const [isFinish, setFinish] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSelTable, setIsSelTable] = useState(false); // selectedRows
  const [selTableRows, setSelTableRows] = useState<UserResponse[] | null>(); // sel table data
  const [rowSelection, setRowSelection] = useState<TableRowSelection<UserResponse> | undefined>(
    undefined
  );
  const [openGroupSubDialog, setOpenGroupSubDialog] = useState(false); // dialog
  const tableData = useRef<UserResponse[]>([]);
  const formFilters = useRef<INIT_FILTERS_REF | null>();
  const [isShowEditDialog, setIsShowEditDialog] = useState(false); //   group sub
  const [lifeTime, setLifeTime] = useState("greaterThan");
  const [countDownDate, setCountDownDate] = useState("greaterThan");

  const fetchData = async (params: any) => {
    try {
      const { data } = await axios.get("/api/list_user", {
        params,
      });
      return { userResponseList: [], ...data };
    } catch (e: any) {
      message.error(`Get user list data error: ${e.message}`);
      return {
        userResponseList: [],
      };
    }
  };

  const resetData = useCallback(() => {
    dataRequest.current = { ...INIT_DATA_REQUEST };
    tableData.current = [];
    requestDataFn();
  }, []);

  const filterSearch = useCallback(
    (value: INIT_FILTERS_REF) => {
      dataRequest.current = { ...INIT_DATA_REQUEST };
      tableData.current = [];
      const tempValue = {
        ...value,
        minUserLifeTime: lifeTime == "greaterThan" ? value.minUserLifeTime : "",
        maxUserLifeTime: lifeTime == "lessThan" ? value.minUserLifeTime : "",
        minCountDownDate: countDownDate == "greaterThan" ? value.minCountDownDate : "",
        maxCountDownDate: countDownDate == "lessThan" ? value.minCountDownDate : "",
      };
      formFilters.current = tempValue;
      requestDataFn();
    },
    [lifeTime, countDownDate]
  );

  const requestDataFn = useCallback(async () => {
    setLoading(true);
    const data = await fetchData({
      ...dataRequest.current,
      current: undefined,
      ...(formFilters.current
        ? {
            ...INIT_FILTERS,
            ...formFilters.current,
          }
        : {}),
    });

    let tempTableData = [];
    if (dataRequest.current.current == 1) {
      tempTableData = data.userResponseList;
    } else {
      tempTableData = [...tableData.current].concat(data.userResponseList);
    }
    tableData.current = tempTableData;
    if (data.nextToken) {
      dataRequest.current = {
        ...dataRequest.current,
        nextToken: data.nextToken && JSON.stringify(data.nextToken),
      };
      setFinish(false);

      if (tempTableData.length < 20) {
        // callBack
        dataRequest.current.current = dataRequest.current.current + 1;
        setLoading(true);
        requestDataFn();
      } else {
        setLoading(false);
      }
    } else {
      setFinish(true);
      setLoading(false);
    }
  }, [dataRequest, tableData]);

  function getDocumentTop() {
    var scrollTop = 0,
      bodyScrollTop = 0,
      documentScrollTop = 0;
    if (document.body) {
      bodyScrollTop = document.body.scrollTop;
    }
    if (document.documentElement) {
      documentScrollTop = document.documentElement.scrollTop;
    }
    scrollTop = bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
    return scrollTop;
  }

  function getWindowHeight() {
    var windowHeight = 0;
    if (document.compatMode == "CSS1Compat") {
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowHeight = document.body.clientHeight;
    }
    return windowHeight;
  }

  function getScrollHeight() {
    var scrollHeight = 0,
      bodyScrollHeight = 0,
      documentScrollHeight = 0;
    if (document.body) {
      bodyScrollHeight = document.body.scrollHeight;
    }
    if (document.documentElement) {
      documentScrollHeight = document.documentElement.scrollHeight;
    }
    scrollHeight =
      bodyScrollHeight - documentScrollHeight > 0 ? bodyScrollHeight : documentScrollHeight;
    return scrollHeight;
  }

  const handScroll = useCallback(() => {
    if (getScrollHeight() - getDocumentTop() - getWindowHeight() < 2) {
      if (!isFinish && !loading) {
        dataRequest.current.current = dataRequest.current.current + 1;
        requestDataFn();
      }
    }
  }, [isFinish, loading, requestDataFn]);

  useEffect(() => {
    requestDataFn();
  }, [requestDataFn]);

  useEffect(() => {
    window.addEventListener("scroll", handScroll);
    return () => {
      window.removeEventListener("scroll", handScroll);
    };
  }, [isFinish, requestDataFn, dataRequest, form, handScroll]);

  const getEditTitle = useCallback((title: string) => {
    return (
      <div className={classnames(style["col-header-title"])}>
        <span className={classnames(style["col-title"])}>{title}</span>
        <EditOutlined />
      </div>
    );
  }, []);

  const getEditRecord = useCallback((record: UserResponse) => {
    return {
      email: record.registerEmail,
      demo_status: record.demoStatus,
      demoed_time: record.demoedTime,
      name_comments: record.nameComments,
      company_comments: record.companyComments,
      telegram_comments: record.telegramComments,
    };
  }, []);

  // select table datas
  const handleSelTableDatas = useCallback((selectedRowKeys: any, selectedRows: any) => {
    setSelTableRows((preRows) => {
      if (preRows && !!preRows.length) {
        let filterNotData: UserResponse[] = [];
        const pageRegisterEmail = tableData.current.map((item) => item.registerEmail);
        preRows.forEach((i) => {
          if (!pageRegisterEmail.includes(i.registerEmail)) {
            filterNotData.push(i);
          }
        });
        return [...filterNotData, ...selectedRows];
      }
      return [...selectedRows];
    });
  }, []);

  const handleRowSelectionChange = (enable: boolean) => {
    setRowSelection(
      enable
        ? {
            type: "checkbox",
            onChange: handleSelTableDatas,
            fixed: true,
          }
        : undefined
    );

    setIsSelTable(true);
  };

  const columns: TableColumnProps<UserResponse>[] = [
    {
      dataIndex: "name",
      title: "Name",
    },
    {
      dataIndex: "nameComments",
      title: () => getEditTitle("Name Comments"),
      render: (text, record) => {
        return <EditColData record={getEditRecord(record)} type="name_comments" />;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
    },
    {
      dataIndex: "registerEmail",
      title: "Registered Email",
    },
    {
      dataIndex: "company",
      title: "Company",
    },
    {
      dataIndex: "companyComments",
      title: () => getEditTitle("Company Comments"),
      render: (text, record) => {
        return <EditColData record={getEditRecord(record)} type="company_comments" />;
      },
    },
    {
      dataIndex: "telegram",
      title: "Telegram",
    },
    {
      dataIndex: "telegramComments",
      title: () => getEditTitle("Telegram Comments"),
      render: (text, record) => {
        return <EditColData record={getEditRecord(record)} type="telegram_comments" />;
      },
    },
    {
      dataIndex: "userLifeTime",
      title: "User Lifeime",
      render: (text) => text && `${text}days`,
    },
    {
      dataIndex: "stripe_payment_email",
      title: "Payment Email",
    },
    {
      dataIndex: "paymentType",
      title: "Payment Type",
    },
    {
      dataIndex: "lastPlan",
      title: "Last Plan",
      render: (text) => text && <div>{convertPlanContent(text)}</div>,
    },
    {
      dataIndex: "currentPlan",
      title: "Current Plan",
      render: (text) => text && <div>{convertPlanContent(text)}</div>,
    },
    {
      dataIndex: "expirationTime",
      title: "Expiration Time",
      render: (text) =>
        text && <div style={{ whiteSpace: "nowrap" }}>{dayjs(text).format("YYYY-MM-DD")}</div>,
    },
    {
      dataIndex: "countDownDate",
      title: "Countdown Date",
      render: (text) => text && `${text}days`,
    },
    {
      dataIndex: "nextPlan",
      title: "Next Plan",
      render: (text) => text && <div>{convertPlanContent(text)}</div>,
    },
    {
      dataIndex: "demoStatus",
      title: () => getEditTitle("Demo Status"),
      render: (text, record) => {
        return <EditColData record={getEditRecord(record)} type="demo_status" />;
      },
    },
    {
      dataIndex: "demoedTime",
      title: () => getEditTitle("Demoed Time"),
      render: (text, record) => {
        return <EditColData record={getEditRecord(record)} type="demoed_time" />;
      },
    },
    {
      dataIndex: "stripe_customer_id",
      title: "Subscription Log",
      render: (text) => {
        if (text) {
          return (
            <a
              href={`${STRIPE_URL_PREFIX[process.env.NEXT_PUBLIC_STAGE!]}/customers/${text}`}
              target="_blank"
              rel="noreferrer"
            >
              View
            </a>
          );
        }
      },
    },
    // {
    //   dataIndex: "groupName",
    //   title: "Group Name",
    // },
    // {
    //   dataIndex: "accountType",
    //   title: "Account Type",
    // },
    {
      title: "actions",
      render: (_, record) => {
        const actionButtons = [];
        const currentPlan = record.currentPlan;
        const status = record.status as OPERATION_PLATFORM_USER_STATUS;
        // https://gx3ykzqaze.larksuite.com/docx/XnsBdARRGomTIQxzr9pul5gfsS8#CtiddprRpol7bgxYuPxuel0PsOf
        if (
          status === OPERATION_PLATFORM_USER_STATUS.EXPIRED ||
          status === OPERATION_PLATFORM_USER_STATUS.CANCELLED ||
          status === OPERATION_PLATFORM_USER_STATUS.TRIAL ||
          currentPlan === MembershipLevel.FREE_TRIAL // Pro Trial
        ) {
          actionButtons.push({
            key: "ExtendPlan",
            label: (
              <ExtendPlan
                planToExtend={
                  status === OPERATION_PLATFORM_USER_STATUS.TRIAL ||
                  status === OPERATION_PLATFORM_USER_STATUS.CANCELLED
                    ? convertPlanContent(record.currentPlan)
                    : convertPlanContent(record.lastPlan)
                }
                email={record.registerEmail}
                expirationTime={record.expirationTime}
                callback={async () => {
                  await resetData();
                }}
              />
            ),
          });
        }
        const lastPlan = record.lastPlan as MembershipLevel;
        // https://gx3ykzqaze.larksuite.yom/docx/XnsBdARRGomTIQxzr9pul5gfsS8#ZPQPd2m8xopXu9x62GwuZ8gYs5e
        if (
          (currentPlan === MembershipLevel.STANDARD || currentPlan === MembershipLevel.FREE) &&
          lastPlan !== MembershipLevel.PRO
        ) {
          actionButtons.push({
            key: "Applytrial_FREE",
            label: (
              <Applytrial
                trialLevel={MembershipLevel.FREE_TRIAL}
                email={record.registerEmail}
                callback={async () => {
                  await resetData();
                }}
              />
            ),
          });
        }
        if (
          currentPlan !== MembershipLevel.BUSINESS &&
          currentPlan !== MembershipLevel.BUSINESS_TRIAL &&
          lastPlan !== MembershipLevel.BUSINESS
        ) {
          actionButtons.push({
            key: "Applytrial_BUSINESS",
            label: (
              <Applytrial
                trialLevel={MembershipLevel.BUSINESS_TRIAL}
                email={record.registerEmail}
                callback={async () => {
                  await resetData();
                }}
              />
            ),
          });
        }

        // add OfflinePayment
        actionButtons.push({
          key: "OfflinePayment",
          label: (
            <OfflinePayment
              email={record.registerEmail}
              callback={async () => {
                await resetData();
              }}
              stripeSubscriptionId={record.stripeSubscriptionId}
            />
          ),
        });
        // add OfflineSubscription
        actionButtons.push({
          key: "OfflineSubscription",
          label: (
            <OfflineSubscription
              email={record.registerEmail}
              paymentEmail={record.stripe_payment_email}
              callback={async () => {
                await resetData();
              }}
            />
          ),
        });

        if (currentPlan !== MembershipLevel.FREE) {
          actionButtons.push({
            key: "SetToFree",
            label: (
              <SetToFree
                email={record.registerEmail}
                stripeSubscriptionId={record.stripeSubscriptionId}
                callback={async () => {
                  await resetData();
                }}
              />
            ),
          });
        }

        // add judge
        if (actionButtons.length > 0 && !isSelTable) {
          return (
            <Dropdown menu={{ items: actionButtons }} trigger={["click"]}>
              <Button>
                Action <DownOutlined />
              </Button>
            </Dropdown>
          );
        }

        return <div>-</div>;
      },
    },
    {
      title: "log",
      render: (_, record) => {
        return <OperatorLog email={record.registerEmail} />;
      },
    },
  ];

  const tableProps: TableProps<UserResponse> = {
    rowSelection,
  };

  return (
    <ComponentAuthProtect>
      <Form
        style={{ marginBottom: "20px" }}
        layout="inline"
        initialValues={INIT_FILTERS}
        form={form}
        onFinish={filterSearch}
      >
        <Form.Item label="Email" name="email" className={classnames(style["form-item"])}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Payment Email"
          name="stripPaymentEmail"
          className={classnames(style["form-item"])}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Payment Type"
          name="paymentType"
          className={classnames(style["form-item"])}
        >
          <Select style={{ width: "200px" }} options={homePaymentType}></Select>
        </Form.Item>
        <Form.Item
          label="Current Plan"
          name="currentPlans"
          className={classnames(style["form-item"])}
        >
          <Select style={{ width: "200px" }}>
            {Object.values(MembershipLevel).map((level) => {
              return (
                <Option key={level} value={level}>
                  {convertPlanContent(level)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Status" name="status" className={classnames(style["form-item"])}>
          <Select style={{ width: "200px" }}>
            {Object.values(OPERATION_PLATFORM_USER_STATUS).map((status) => {
              return (
                <Option key={status} value={status}>
                  {status}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Countdown Date"
          name="minCountDownDate"
          className={classnames(style["form-item"])}
        >
          <div className={classnames(style["LifeTime-select"])}>
            <Select
              value={countDownDate}
              onChange={(val) => setCountDownDate(val)}
              className={classnames(style["LifeTime-select-time"])}
              style={{ fontSize: "18px" }}
            >
              <Select.Option value="greaterThan">greater than</Select.Option>
              <Select.Option value="lessThan">less than</Select.Option>
            </Select>
            <Input suffix="days" className={classnames(style["userLifeTime-filter"])} />
          </div>
        </Form.Item>
        <Form.Item
          label="User LifeTime"
          name="minUserLifeTime"
          className={classnames(style["form-item"])}
        >
          <div className={classnames(style["LifeTime-select"])}>
            <Select
              value={lifeTime}
              onChange={(val) => setLifeTime(val)}
              className={classnames(style["LifeTime-select-time"])}
              style={{ fontSize: "18px" }}
            >
              <Select.Option value="greaterThan">greater than</Select.Option>
              <Select.Option value="lessThan">less than</Select.Option>
            </Select>
            <Input suffix="days" className={classnames(style["userLifeTime-filter"])} />
          </div>
        </Form.Item>
        <Button
          loading={loading}
          disabled={loading}
          type="primary"
          htmlType="submit"
          className={classnames(style["form-item-btn"])}
        >
          Submit
        </Button>
        <Button
          htmlType="button"
          onClick={() => {
            form.resetFields();
          }}
          disabled={loading}
          loading={loading}
        >
          Reset
        </Button>
      </Form>
      {/* <div>
        {isSelTable ? (
          <Button
            type="primary"
            loading={loading}
            disabled={loading}
            style={{ marginBottom: 10 }}
            onClick={() => {
              setOpenGroupSubDialog(true);
            }}
          >
            Next Step
          </Button>
        ) : (
          <Button
            type="primary"
            loading={loading}
            disabled={loading}
            style={{ marginBottom: 10 }}
            onClick={() => {
              handleRowSelectionChange(true);
            }}
          >
            Group Subscription
          </Button>
        )}
      </div> */}
      <div className={classnames("mainTablel", style["page-Tablel"])} style={{ overflow: "auto" }}>
        <Table
          {...tableProps}
          columns={columns}
          dataSource={tableData.current}
          rowKey="registerEmail"
          pagination={false}
          loading={loading}
        />
      </div>
      {isFinish && <div className={classnames(style["no-data"])}>No More</div>}
      {openGroupSubDialog ? (
        // 选项弹窗
        <GroupSubDialog
          selRows={selTableRows}
          isShowEditDialog={openGroupSubDialog}
          callBack={async () => {
            await resetData();
          }}
          cancel={() => {
            setOpenGroupSubDialog(false);
            setIsSelTable(false);
            setRowSelection(undefined);
            setSelTableRows([]);
          }}
        />
      ) : (
        <></>
      )}
    </ComponentAuthProtect>
  );
};

export default Home;
