{
  /* eslint-disable-next-line */
}
import {
  message,
  Modal,
  Form,
  Input,
  Table,
  Button,
  Checkbox,
  Space,
  TableColumnProps,
} from "antd";
import { useEffect, useState, useCallback, useRef } from "react";
import { convertPlanContent } from "@/src/common/tools";
import { useSession } from "next-auth/react";
import type { UserResponse } from "../backend/types/response";
import axios from "axios";

type EDIT_COL = {
  email: string;
  demo_status?: string;
  demoed_time?: any;
  name_comments?: string;
  company_comments?: string;
  telegram_comments?: string;
};
export type EDIT_COL_REF = {
  title: string;
  type: string;
  record: EDIT_COL;
};

type UserResponseRes = UserResponse & { isSel: boolean };

const GroupSubDialog = ({
  isShowEditDialog,
  selRows,
  callBack,
  cancel,
}: {
  isShowEditDialog: boolean;
  selRows: any;
  callBack: () => void;
  cancel: () => void;
}) => {
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const { data: session } = useSession();
  const [selTableRows, setSelTableRows] = useState<UserResponseRes[]>(); // seats accounts
  const [mainAccounts, setMainAccounts] = useState<UserResponseRes[]>(); // main accounts
  const [searchable, setSearchable] = useState(true); // search state
  const [searchLoading, setSearchLoading] = useState(false); // search loading
  const [submitable, setSubmitable] = useState(false); // submit state
  const [submitLoading, setSubmitLoading] = useState(false); // submit loading
  const [mainState, setMainState] = useState(false); // main account sel state
  const [seatsState, setSeatsState] = useState(false); // seats account sel state
  const values = Form.useWatch([], form);
  useEffect(() => {
    // main state
    const selMain = mainAccounts?.filter((item) => item.isSel);
    if (selMain && Array.isArray(selMain) && selMain.length > 0 && selMain[0].status !== "Active") {
      setMainState(true);
    } else {
      setMainState(false);
    }

    // seats state
    const selSeats = selTableRows?.filter((item) => item.isSel);
    let flag = 0;
    if (selSeats && Array.isArray(selSeats) && selSeats.length > 0) {
      selSeats.map((item) => {
        if (["Expired", "Registered", "Trial"].includes(item.status)) {
          flag++;
        }
      });

      if (flag === selSeats.length) {
        setSeatsState(false);
      } else {
        setSeatsState(true);
      }
    } else {
      setSeatsState(false);
    }
  }, [selTableRows, mainAccounts]);

  useEffect(() => {
    if (selRows && Array.isArray(selRows) && selRows.length > 0) {
      setSelTableRows(
        selRows?.map((item) => {
          return {
            ...item,
            isSel: true,
          };
        })
      );
    }
    setShowModal(isShowEditDialog);
  }, []);

  useEffect(() => {
    if (
      !searchable &&
      !mainState &&
      !seatsState &&
      mainAccounts &&
      selTableRows &&
      mainAccounts?.filter((item) => item.isSel).length > 0 &&
      selTableRows?.filter((item) => item.isSel).length > 0
    ) {
      setSubmitable(true);
    } else {
      setSubmitable(false);
    }
  }, [selTableRows, mainAccounts, searchable, mainState, seatsState]);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      (formData) => {
        if (formData.groupName && formData.email) {
          setSearchable(false);
        } else {
          setSearchable(true);
        }
      },
      (formData) => {
        setSearchable(true);
      }
    );
  }, [values]);

  // submit api
  const fetchData = async (data: any) => {
    return axios.post("/api/group_subscription", data);
  };

  // Select a main account  - jobeybei@gmail.com
  const selMainAccount = async () => {
    setSearchLoading(true);

    const { email } = form.getFieldsValue(true);

    try {
      const res = await axios.get("/api/list_user", {
        params: {
          email,
          limit: 20,
        },
      });
      setSearchLoading(false);
      if (
        res.status === 200 &&
        Array.isArray(res?.data?.userResponseList) &&
        res?.data?.userResponseList.length > 0
      ) {
        setMainAccounts(
          res?.data?.userResponseList.map((item: any) => {
            return {
              ...item,
              isSel: false,
            };
          })
        ); // 主账号
      }
    } catch (e: any) {
      setSearchLoading(false);
      message.error(`Query main account error: ${e?.message}`);
    }
  };

  // submit
  const handleOk = useCallback(async () => {
    const formData = form.getFieldsValue(true);

    const params = {
      parent_email: mainAccounts?.filter((item) => item.isSel)[0].registerEmail,
      child_email_list: selTableRows
        ?.filter((item) => item.isSel)
        .map((item) => item.registerEmail),
      group_name: formData.groupName,
      operator_username: session?.user.username,
    };
    setSubmitLoading(true);
    const res = await fetchData(params);
    setSubmitLoading(false);
    if (res.status == 200) {
      message.success("Submit successfully!");
      handleCancel();
      callBack();
    } else {
      message.error("Submit failed!");
    }
  }, [callBack, form, session?.user.username, mainAccounts, selTableRows]);

  // cancel
  const handleCancel = useCallback(() => {
    setShowModal(false);
    cancel();
    form.resetFields();
  }, [form]);

  // check change
  const checkChange = (index: number, checked: boolean) => {
    if (selTableRows && Array.isArray(selTableRows) && selTableRows.length > 0) {
      const newSelTableRows = selTableRows.map((item, inIndex) => {
        return {
          ...item,
          isSel: inIndex === index ? checked : item.isSel,
        };
      });
      setSelTableRows(newSelTableRows);
    }
  };

  // check change
  const mainCheckChange = (index: number, checked: boolean) => {
    if (mainAccounts && Array.isArray(mainAccounts) && mainAccounts.length > 0) {
      const newMainAccounts = mainAccounts.map((item, inIndex) => {
        return {
          ...item,
          isSel: inIndex === index ? checked : false,
        };
      });
      setMainAccounts(newMainAccounts);
    }
  };

  const mainColumns: TableColumnProps<UserResponseRes>[] = [
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      dataIndex: "registerEmail",
      title: "Registered Email",
    },
    {
      dataIndex: "currentPlan",
      title: "Current Plan",
      render: (text) => text && <div>{convertPlanContent(text)}</div>,
    },
    {
      title: "",
      key: "action",
      render: (text, record, index) => (
        <Checkbox
          checked={text.isSel}
          key={`${text.registerEmail}`}
          onChange={(e) => {
            mainCheckChange(index, e.target.checked);
          }}
        ></Checkbox>
      ),
    },
  ];

  // table columns
  const seatsColumns: TableColumnProps<UserResponseRes>[] = [
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      dataIndex: "registerEmail",
      title: "Registered Email",
    },
    {
      dataIndex: "currentPlan",
      title: "Current Plan",
      render: (text) => text && <div>{convertPlanContent(text)}</div>,
    },
    {
      title: "",
      key: "action",
      render: (text, record, index) => (
        <Checkbox
          checked={text.isSel}
          key={`${text.registerEmail}`}
          onChange={(e) => {
            checkChange(index, e.target.checked);
          }}
        ></Checkbox>
      ),
    },
  ];

  return (
    <Modal
      title=""
      destroyOnClose
      open={showModal}
      onCancel={handleCancel}
      footer={null}
      width="66%"
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 10 }}
        autoComplete="off"
      >
        <h4>Group Subscription</h4>
        <Space>
          <Form.Item
            label="Group Name"
            name="groupName"
            style={{ minWidth: 320 }}
            rules={[{ required: true, message: "Please input Group Name!" }]}
          >
            <Input style={{ minWidth: 200 }} />
          </Form.Item>
        </Space>
        <h4>Select a main account</h4>
        <Space style={{ alignItems: "baseline" }}>
          <Form.Item
            label="Email"
            name="email"
            style={{ minWidth: 320 }}
            rules={[{ required: true, message: `Please input Email!` }]}
          >
            <Input style={{ minWidth: 200 }} />
          </Form.Item>
          <Button
            type="primary"
            disabled={searchable}
            style={{ marginLeft: 20 }}
            loading={searchLoading}
            onClick={selMainAccount}
          >
            Search
          </Button>
        </Space>
        <Table pagination={false} dataSource={mainAccounts} columns={mainColumns} />
        {mainState ? (
          <div style={{ color: "red" }}>Error: The main account must be active</div>
        ) : (
          <></>
        )}
        <h4>The Seats selected</h4>
        <Table pagination={false} dataSource={selTableRows} columns={seatsColumns} />
        {seatsState ? (
          <div style={{ color: "red" }}>
            Error: The seat account cannot be in Active or Cancelled status.
          </div>
        ) : (
          <></>
        )}
        <div style={{ textAlign: "right", paddingTop: 10 }}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={handleOk}
            disabled={!submitable}
            loading={submitLoading}
            type="primary"
            style={{ marginLeft: 10 }}
          >
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default GroupSubDialog;
