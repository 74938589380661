import { message, Form, Input, DatePicker, Select } from "antd";
import { useState, useCallback, useEffect } from "react";
import type { UserResponse } from "../backend/types/response";
import { useSession } from "next-auth/react";
import axios from "axios";
import dayjs from "dayjs";
import { LoadingOutlined, CalendarOutlined } from "@ant-design/icons";
import classnames from "classnames";
import style from "@/styles/homePage.module.scss";
import { demoOptions } from "../common/const";
import React from "react";

type EDIT_COL = {
  email: string;
  demo_status?: string;
  demoed_time?: any;
  name_comments?: string;
  company_comments?: string;
  telegram_comments?: string;
};

type EDIT_COL_type =
  | "demo_status"
  | "demoed_time"
  | "name_comments"
  | "company_comments"
  | "telegram_comments";

const EditColData = ({ record, type }: { record: EDIT_COL; type: EDIT_COL_type }) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { data: session } = useSession();
  const [currentText, setCurrentText] = useState<any>("");

  useEffect(() => {
    if (type == "demoed_time" && record[type]) {
      setCurrentText(dayjs(record[type]).format("YYYY-MM-DD"));
    } else {
      setCurrentText(record[type]);
    }
    form.setFieldsValue({
      [type]: type == "demoed_time" && record[type] ? dayjs(record[type]) : record[type],
    });
  }, [form, record, type]);

  const fetchData = async (data: any) => {
    return axios.post("/api/update_operator_note", data);
  };

  const handleCancel = useCallback(() => {
    setEditing(false);
  }, []);

  const handleOk = useCallback(async () => {
    const formData = form.getFieldsValue(true);
    let tempFormData = { ...formData };
    if (type == "demoed_time" && tempFormData.demoed_time) {
      tempFormData.demoed_time = dayjs(tempFormData["demoed_time"]).valueOf();
    }
    const params = {
      operator_username: session?.user.username,
      ...record,
      ...tempFormData,
    };
    setLoading(true);
    const res = await fetchData(params);
    if (res.status == 200) {
      message.success("Modified successfully!");

      if (type == "demoed_time" && tempFormData[type]) {
        setCurrentText(dayjs(tempFormData[type]).format("YYYY-MM-DD"));
      } else {
        setCurrentText(tempFormData[type]);
      }
      handleCancel();
    } else {
      message.error("Modified failed!");
    }
    setLoading(false);
  }, [form, handleCancel, record, session, type]);

  const toggleEdit = useCallback(() => {
    setEditing(true);
  }, []);

  return (
    <>
      {editing ? (
        <Form name="basic" form={form} autoComplete="off">
          <Form.Item<UserResponse>
            label=""
            name={type}
            // rules={[{ required: true, message: `Please input ${currentEditRow.title}!` }]}
            // style={{ padding: "40px 30px 20px 0px" }}
          >
            {type == "demoed_time" ? (
              <DatePicker
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                onBlur={handleOk}
                onOk={handleOk}
                autoFocus
                suffixIcon={
                  <>
                    {loading ? (
                      <LoadingOutlined style={{ fontSize: 12 }} spin rev={undefined} />
                    ) : (
                      <CalendarOutlined rev={undefined} />
                    )}
                  </>
                }
              />
            ) : type == "demo_status" ? (
              <Select options={demoOptions} onBlur={handleOk} allowClear />
            ) : (
              <Input
                suffix={
                  <>
                    {loading && <LoadingOutlined style={{ fontSize: 10 }} spin rev={undefined} />}
                  </>
                }
                onPressEnter={handleOk}
                onBlur={handleOk}
              />
            )}
          </Form.Item>
        </Form>
      ) : (
        <div onClick={toggleEdit}>
          <div className={classnames(style["editCol"])}>{currentText}</div>
        </div>
      )}
    </>
  );
};

export default EditColData;
