import { ReactNode, useEffect } from "react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

export const ComponentAuthProtect = ({ children }: { children: ReactNode }) => {
  const { status, data } = useSession();
  const r = useRouter();
  useEffect(() => {
    if (status === "loading") return;
    if (status === "unauthenticated") {
      r.push("/login");
    }
  }, [r, status]);

  return <>{status === "authenticated" && children}</>;
};
